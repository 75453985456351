<template>
  <div v-show="isVisible" class="pxp-blog-posts-carousel-1 mt-4 mt-md-5">
    <lazy-library-title
      text="Latest Blogs"
      class="tw-mb-16"
    ></lazy-library-title>

    <template v-if="carouselType === 'primary'">
      <div
        id="pxp-blog-posts-carousel-1-img"
        class="carousel slide pxp-blog-posts-carousel-1-img tw-flex tw-justify-center"
        data-ride="carousel"
        data-pause="false"
        data-interval="false"
      >
        <div class="carousel-inner tw-w-11/12">
          <div
            v-for="(blog, index) in blogs"
            :key="`blog-carousel-${index}`"
            class="carousel-item"
            :data-slide="index"
            :class="index === 0 ? 'active' : ''"
          >
            <nuxt-link
              :to="`/blogs/${blog.url}`"
              :external="true"
              class="pxp-hero-bg pxp-cover"
              style="background-position: 50% 50%"
              :style="`background-image: url(${blog.largeImage});`"
            ></nuxt-link>
          </div>
        </div>
      </div>

      <div class="pxp-carousel-controls tw-drop-shadow-lg">
        <a
          class="pxp-carousel-control-prev !tw-bg-[var(--isabelline)]"
          role="button"
          data-slide="prev"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.414"
            height="20.828"
            viewBox="0 0 32.414 20.828"
          >
            <g
              id="Group_30"
              data-name="Group 30"
              transform="translate(-1845.086 -1586.086)"
            >
              <line
                id="Line_2"
                data-name="Line 2"
                x1="30"
                transform="translate(1846.5 1596.5)"
                fill="none"
                stroke="#333"
                stroke-linecap="round"
                stroke-width="2"
              ></line>
              <line
                id="Line_3"
                data-name="Line 3"
                x1="9"
                y2="9"
                transform="translate(1846.5 1587.5)"
                fill="none"
                stroke="#333"
                stroke-linecap="round"
                stroke-width="2"
              ></line>
              <line
                id="Line_4"
                data-name="Line 4"
                x1="9"
                y1="9"
                transform="translate(1846.5 1596.5)"
                fill="none"
                stroke="#333"
                stroke-linecap="round"
                stroke-width="2"
              ></line>
            </g>
          </svg>
        </a>
        <a
          class="pxp-carousel-control-next !tw-bg-[var(--isabelline)]"
          role="button"
          data-slide="next"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.414"
            height="20.828"
            viewBox="0 0 32.414 20.828"
          >
            <g
              id="Symbol_1_1"
              data-name="Symbol 1 – 1"
              transform="translate(-1847.5 -1589.086)"
            >
              <line
                id="Line_5"
                data-name="Line 2"
                x2="30"
                transform="translate(1848.5 1599.5)"
                fill="none"
                stroke="#333"
                stroke-linecap="round"
                stroke-width="2"
              ></line>
              <line
                id="Line_6"
                data-name="Line 3"
                x2="9"
                y2="9"
                transform="translate(1869.5 1590.5)"
                fill="none"
                stroke="#333"
                stroke-linecap="round"
                stroke-width="2"
              ></line>
              <line
                id="Line_7"
                data-name="Line 4"
                y1="9"
                x2="9"
                transform="translate(1869.5 1599.5)"
                fill="none"
                stroke="#333"
                stroke-linecap="round"
                stroke-width="2"
              ></line>
            </g>
          </svg>
        </a>
      </div>

      <div
        class="pxp-blog-posts-carousel-1-caption-container tw-bg-[var(--isabelline)] tw-pb-9 tw-drop-shadow-lg"
      >
        <div
          id="pxp-blog-posts-carousel-1-caption"
          class="carousel slide pxp-blog-posts-carousel-1-caption"
          data-ride="carousel"
          data-pause="false"
          data-interval="false"
        >
          <div class="carousel-inner">
            <nuxt-link
              v-for="(blog, index) in blogs"
              :key="`blog-carousel-${index}`"
              :to="`/blogs/${blog.url}`"
              :external="true"
              class="carousel-item tw-h-[150px]"
              :data-slide="index"
              :class="index === 0 ? 'active' : ''"
            >
              <div class="pxp-blog-posts-carousel-1-caption-category"></div>
              <div class="pxp-blog-posts-carousel-1-caption-title">
                <lazy-library-title tag="h3" :text="blog.title">
                </lazy-library-title>
              </div>
              <div
                class="pxp-blog-posts-carousel-1-caption-summary tw-line-clamp-3 tw-overflow-hidden tw-text-ellipsis"
              >
                <lazy-library-text tag="span" :text="blog.description">
                </lazy-library-text>
              </div>
            </nuxt-link>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="carouselType === 'secondary'">
      <div id="carouselExampleFade" class="carousel slide carousel-fade">
        <div class="carousel-inner">
          <nuxt-link
            v-for="(blog, index) in blogs"
            :key="`blog-carousel-${index}`"
            class="carousel-item secondary-slider"
            :class="index === 0 ? 'active' : ''"
            :title="blog.title"
            :to="`/blogs/${blog.url}`"
            :external="true"
          >
            <img
              :src="blog.largeImage"
              class="d-block w-100"
              :alt="blog.title"
            />
            <div style="position: absolute; left: 2%; bottom: 4%; z-index: 1; width: 60%">
              <library-title
                tag="h2"
                :text="blog.title"
                style="color: var(--white)"
                class="tw-line-clamp-2 md:tw-line-clamp-3"
              ></library-title>
            </div>
          </nuxt-link>
        </div>
        <div
          style="
            position: absolute;
            bottom: 2%;
            right: 1%;
            background-color: var(--white);
            z-index: 1;
            border-radius: 4px;
          "
          class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-py-[20px] md:tw-py-[25px] tw-px-[25px] md:tw-px-[55px] tw-gap-y-3 md:tw-gap-y-0"
        >
          <button
            type="button"
            class="tw-mr-4"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32.414"
              height="20.828"
              viewBox="0 0 32.414 20.828"
            >
              <g
                id="Group_30"
                data-name="Group 30"
                transform="translate(-1845.086 -1586.086)"
              >
                <line
                  id="Line_2"
                  data-name="Line 2"
                  x1="30"
                  transform="translate(1846.5 1596.5)"
                  fill="none"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-width="2"
                ></line>
                <line
                  id="Line_3"
                  data-name="Line 3"
                  x1="9"
                  y2="9"
                  transform="translate(1846.5 1587.5)"
                  fill="none"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-width="2"
                ></line>
                <line
                  id="Line_4"
                  data-name="Line 4"
                  x1="9"
                  y1="9"
                  transform="translate(1846.5 1596.5)"
                  fill="none"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-width="2"
                ></line>
              </g>
            </svg>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32.414"
              height="20.828"
              viewBox="0 0 32.414 20.828"
            >
              <g
                id="Symbol_1_1"
                data-name="Symbol 1 – 1"
                transform="translate(-1847.5 -1589.086)"
              >
                <line
                  id="Line_5"
                  data-name="Line 2"
                  x2="30"
                  transform="translate(1848.5 1599.5)"
                  fill="none"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-width="2"
                ></line>
                <line
                  id="Line_6"
                  data-name="Line 3"
                  x2="9"
                  y2="9"
                  transform="translate(1869.5 1590.5)"
                  fill="none"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-width="2"
                ></line>
                <line
                  id="Line_7"
                  data-name="Line 4"
                  y1="9"
                  x2="9"
                  transform="translate(1869.5 1599.5)"
                  fill="none"
                  stroke="#666666"
                  stroke-linecap="round"
                  stroke-width="2"
                ></line>
              </g>
            </svg>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { jQuery } from 'public/theme3/js/custom';
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';

export default defineNuxtComponent({
  name: 'Theme3LatestBlogs',

  mixins: [ComponentMixin, BlogsMixin],

  data() {
    return {};
  },

  head() {
    return {
      script: [
        {
          key: 'bootstrap-min-5.3.3',
          type: 'text/javascript',
          src: `/theme3/js/bootstrap.min.js`,
          body: true,
          async: false,
        },
      ],
    };
  },

  computed: {
    isVisible() {
      const route = useRoute();
      return !route.query.q && !route.query.page;
    },

    carouselType(): 'primary' | 'secondary' {
      if (!this.vars.latestCarouselTypeText) {
        return 'primary';
      }
      return this.vars.latestCarouselTypeText as 'primary' | 'secondary';
    },
  },

  methods: {
    initCarousel() {
      jQuery.featuredBlogsSlider();
    },
  },

  watch: {
    blogs() {
      this.initCarousel();
    },
  },

  mounted() {
    this.initCarousel();
  },
});
</script>

<style scoped>
.secondary-slider:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.secondary-slider:hover.secondary-slider:before {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>