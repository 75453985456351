<template>
  <div class="container mt-100">
    <div class="tw-grid tw-grid-cols-12 tw-items-center">
      <div class="tw-col-span-12 tw-pb-6 lg:tw-pb-0 lg:tw-col-span-7">
        <h2
          class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl tw-mb-7 lg:tw-mb-12"
        >
          {{ vars.titleText }}
        </h2>
        <p v-html="vars.descriptionText" />
      </div>
      <div class="tw-col-span-1"></div>
      <div class="tw-col-span-12 lg:tw-col-span-4">
        <div
          class="tw-py-16 tw-px-12 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-12 tw-rounded-md"
          :style="`background-color: var(--${vars.cardBackgroundColor})`"
        >
          <div
            class="tw-bg-white tw-px-5 tw-py-3 tw-rounded-full font-arial-bold tw-text-sm"
          >
            {{ vars.cardLabelText }}
          </div>
          <NuxtImg
            :src="vars.cardIcon"
            class="icon"
            width="100"
            height="100"
            :alt="vars.titleText"
            :title="vars.TitleText"
          />
          <div class="tw-text-white tw-text-center">
            <h2 class="font-arial-bold tw-text-2xl tw-mb-6">
              {{ vars.cardTitleText }}
            </h2>
            <p>
              {{ vars.cardDescriptionText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'BestPackage',

  mixins: [ComponentMixin],
});
</script>

<style scoped>
.pxp-plans-1-item:hover {
  border: none;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-title {
  transform: translateY(-42px) scale(1.1);
  color: white;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-price {
  color: white;
}

img.icon {
  filter: invert(100%) sepia(1%) saturate(343%) hue-rotate(156deg)
    brightness(118%) contrast(100%);
}
</style>
