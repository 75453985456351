<template>
  <section
    class="tw-h-[65vh] tw-bg-cover tw-bg-center tw-flex tw-justify-center tw-items-end tw-pb-24 section-intro-image"
    :style="[`background-image: url(${vars.backgroundImage})`, doesCircleCtaExist ? 'padding-top: 720px' : '']"
  >
    <div class="container" style="z-index: 3">
      <div class="tw-max-w-2xl">
        <library-title
          tag="h1"
          :text="vars.titleText"
          class="tw-text-white tw-text-5xl lg:!tw-text-6xl tw-mb-6"
          style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)"
        />
        <p
          v-if="vars.descriptionText"
          class="font-arial tw-text-white tw-mb-6 lg:tw-mb-10"
          style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2)"
        >
          {{ vars.descriptionText }}
        </p>
        <div
          v-if="vars.buttons1LabelText.length > 0"
          class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-items-center tw-gap-4"
          style="z-index: 3"
        >
          <div v-for="(button, index) in buttons" :key="`button-${index}`">
            <nuxt-link
              :to="button.href"
              :external="true"
              :class="{
                'pxp-search-properties-btn': button.type === 'primary',
                'pxp-primary-cta pxp-animate after:tw-border-inherit':
                  button.type === 'secondary',
              }"
              :style="`background-color: var(--${
                button.background
              }); color: var(--${button.text}); ${
                button.type === 'secondary'
                  ? `border-top-color: var(--${button.text});`
                  : ''
              }`"
            >
              <!-- TODO COCKPIT for only Grouped Variables: background-> background-color, text -> text-color -->
              {{ button.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'SectionIntro',

  mixins: [ComponentMixin],

  data() {
      return {
        doesCircleCtaExist: false,
      };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  mounted() {
      const circleCTA = document.getElementById('theme3-circle-cta');
      if (circleCTA) {
        this.doesCircleCtaExist = true;
      }
  },
});
</script>

<style scoped>
.section-intro-image {
  position: relative;
}

.section-intro-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Black background with 50% opacity */
  z-index: 1;
}
</style>
