<template>
  <div style="position: relative">
    <div>
      <library-container class="tw-flex tw-flex-col tw-py-24">
        <div class="tw-text-center tw-mb-16">
          <library-title :text="vars.titleText" tag="h2"></library-title>
        </div>
        <div
          class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-items-center md:tw-gap-x-36 tw-gap-y-12"
        >
          <nuxt-link
            v-for="(item, index) in items"
            :key="`item-${key}-${index}`"
            :external="true"
            :to="item.button_link"
            class="tw-col-span-12 md:tw-col-span-6 tw-gap-y-3 tw-flex tw-flex-col tw-w-[370px]"
          >
            <library-image
              :src="item.photo"
              loading="lazy"
              :alt="vars.titleText"
              :w-ratio="1.5"
              :h-ratio="0.6667"
              class="pxp-cta-3-image pxp-cover rounded-lg tw-h-[230px] tw-w-[370px]"
            />
            <lazy-library-title tag="h3" :text="item.title">
            </lazy-library-title>
            <div style="height: 150px">
              <lazy-library-text :text="item.description"></lazy-library-text>
            </div>
            <div
              class="pxp-primary-cta text-uppercase tw-text-sm tw-mt-3 pxp-animate"
            >
              {{ item.button_label }}
            </div>
          </nuxt-link>
        </div>
      </library-container>
    </div>

    <div
      class="tw-absolute tw-w-0 xl:tw-w-[200px] tw-h-full tw-top-0 tw-right-0"
      style="background-color: var(--purple)"
    ></div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { produceKey } from '~/server/core/helpers';

export default defineNuxtComponent({
  name: 'Theme3ClassicCardGridSection',

  mixins: [ComponentMixin],

  data(vm) {
    return {
      key: produceKey(),
    };
  },

  computed: {
    items() {
      return this.groupedVariables.items;
    },
  },
});
</script>
