<template>
  <section
    class="pb-100"
    :style="`background-color: var(--${vars.backgroundColor})`"
  >
    <div class="container">
      <div class="tw-text-center tw-pb-20">
        <h2 class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl">
          {{ vars.sectionTitleText }}
        </h2>
      </div>
      <div
        class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-20 tw-text-center"
      >
        <div
          v-for="(award, index) in awards"
          :key="`award-${index}`"
          class="tw-flex tw-flex-col tw-items-center tw-gap-3"
        >
          <NuxtImg
            :src="award.photo"
            loading="lazy"
            :alt="vars.sectionTitleText"
            quality="60"
            class="tw-max-h-36"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Awards',

  mixins: [ComponentMixin],

  computed: {
    awards() {
      return this.groupedVariables.awards;
    },
  },
});
</script>
