<template>
  <div class="container mt-100">
    <h2
      class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl tw-text-center"
    >
      {{ vars.titleText }}
    </h2>
    <div
      class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-6 tw-justify-center tw-items-center tw-pt-12 tw-gap-12"
    >
      <template v-for="(item, index) in platforms" :key="`platforms-${index}`">
        <NuxtImg
          class="tw-mx-auto"
          width="150"
          :src="item.platform"
          :alt="item.platform"
          :title="item.platform"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'MajorPlatforms',

  mixins: [ComponentMixin],

  computed: {
    platforms() {
      return this.groupedVariables.platforms;
    },
  },
});
</script>

<style scoped></style>
