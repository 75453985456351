<template>
  <div class="container">
    <div class="row g-5 tw-mt-4 lg:tw-mt-12 d-flex justify-content-between">
      <div class="col-lg-7 pt-3">
        <lazy-library-form v-if="form" :form="form" :component="component" />
      </div>
      <div class="col-lg-5">
        <div class="image-wrapper blog h-100">
          <img
            alt=""
            loading="lazy"
            :src="vars.guideImage"
            class="cover-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'GuideForm',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },
  created() {
    try {
      const form = getVariable(this.component, 'guide-form') as string;
      const parsedForm = JSON.parse(form);
      if (form && Object.keys(parsedForm).length) {
        this.form = parsedForm;
      }
    } catch (e) {}
  },
});
</script>

<script setup lang="ts"></script>

<style scoped>
@media screen and (max-width: 479px) {
  .image-wrapper.blog {
    width: unset;
  }
}
</style>
