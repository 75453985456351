<template>
  <div class="vh-100" style="background-color: var(--seashell)">
    <div id="wrapper">
      <div class="video-content">
        <h2
          style="
            color: var(--white);
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
          "
          class="font-plus-jakarta-extrabold tw-text-5xl lg:tw-text-8xl"
          v-html="overlayTitle"
        ></h2>
        <div class="mt-4 tw-flex tw-items-center tw-gap-5">
          <nuxt-link
            :to="vars.mainButtonLinkUrl"
            class="pxp-search-properties-btn"
            :style="`background-color: var(--${vars.mainButtonBackgroundColor}); color: var(--${vars.mainButtonTextColor})`"
            >{{ vars.mainButtonTitleText }}</nuxt-link
          >
          <nuxt-link
            :to="vars.secondaryButtonUrl"
            class="pxp-primary-cta text-uppercase pxp-animate tw-cursor-pointer after:tw-border-white"
            :style="`color: var(--${vars.secondaryButtonTextColor})`"
            >{{ vars.secondaryButtonTitleText }}</nuxt-link
          >
        </div>
      </div>
      <div class="video-wrap">
        <video
          id="video"
          muted
          :loop="true"
          playsinline
          preload="none"
          :src="variables.video"
        >
          <source
            type="video/mp4"
            :video-hd="variables.video"
            :video-sd="variables.video"
          />
        </video>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoBackground',

  mixins: [ComponentMixin],

  computed: {
    overlayTitle() {
      return getVariable(this.component, 'overlay_title-html');
    },

    variables() {
      return {
        video: getVariable(this.component, 'background-video'),
      };
    },
  },

  mounted() {
    const myVideo = document.getElementById('video');
    if (myVideo !== undefined) {
      myVideo.play();
    }
  },
});
</script>

<style scoped>
#wrapper {
  position: relative;
}
#wrapper video {
  position: relative;
  z-index: 100;
}
#wrapper .video-content {
  position: absolute;
  display: block;
  z-index: 150;
  left: 3%;
  bottom: 12%;
}

.video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: no-repeat center center/cover;
}
video {
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  object-fit: cover;
  background: no-repeat center center/cover;
}
</style>
