<template>
  <div
    class="pxp-video-section pxp-cover tw-pt-24"
    :style="`background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${vars.backgroundImage})`"
  >
    <div class="container">
      <library-title
        tag="h2"
        class="pxp-section-h2 text-center text-white"
        :text="vars.titleText"
      >
      </library-title>
      <library-text
        :html="vars.descriptionHtml"
        class="pxp-text-light text-center text-white tw-mt-4 tw-leading-4"
      ></library-text>
      <div class="pt-100 pb-100 text-center">
        <a
          class="pxp-video-section-trigger tw-cursor-pointer"
          @click="togglePopup(true)"
        >
          <span class="fa fa-play"></span>
        </a>
      </div>
    </div>
    <library-popups-video-popup
      v-model:is-visible="isPopupVisible"
      :video-url="vars.contentVideo"
    />
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme3FeaturedVideoSection',

  mixins: [ComponentMixin],

  head() {
    return {};
  },

  data() {
    return {
      isPopupVisible: false,
    };
  },

  methods: {
    togglePopup(status = false) {
      this.isPopupVisible = status;
    },
  },
});
</script>
