<template>
  <div
    class="container mt-100"
    :style="`background-color: ${vars.backgroundColor}`"
  >
    <h2
      class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl tw-text-center"
    >
      {{ vars.titleText }}
    </h2>
    <div class="row mt-5">
      <div
        v-for="(item, index) in packages"
        :key="`package-${index}`"
        class="col-sm-12 col-md-6 col-lg-4"
      >
        <div
          class="pxp-plans-1-item pxp-is-popular tw-min-h-[420px] md:tw-min-h-[520px] lg:tw-min-h-[540px] xl:tw-min-h-[460px] tw-p-12 tw-border-none"
          :class="`tw-bg-[--isabelline] hover:tw-bg-[--camel]`"
        >
          <div
            v-if="item.has_label"
            class="pxp-plans-1-item-label tw-bg-white tw-text-black"
          >
            {{ item.label }}
          </div>
          <div
            class="pxp-plans-1-item-fig tw-flex tw-justify-center tw-items-center"
          >
            <NuxtImg :src="item.card" :alt="item.title" :title="item.title" />
          </div>
          <div
            class="pxp-plans-1-item-title font-arial-bold tw-text-2xl"
            :class="`tw-text-[--${vars.cardTextColor}]`"
          >
            {{ item.title }}
          </div>
          <div class="pxp-plans-1-item-price">
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Packages',

  mixins: [ComponentMixin],

  computed: {
    packages() {
      return this.groupedVariables.packages;
    },
  },
});
</script>

<style scoped>
.pxp-plans-1-item:hover {
  border: none;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-title {
  transform: translateY(-42px) scale(1.1);
  color: white;
}
.pxp-plans-1-item:hover .pxp-plans-1-item-price {
  color: white;
}

.pxp-plans-1-item:hover img {
  filter: invert(100%) sepia(1%) saturate(343%) hue-rotate(156deg)
    brightness(118%) contrast(100%);
}
</style>
