<template>
  <div>
    <div class="tw-flex tw-flex-col tw-gap-y-4">
      <lazy-library-title
        v-if="property.featured_video"
        text="Property Video"
        tag="h3"
      ></lazy-library-title>
      <div v-if="property.featured_video">
        <lazy-theme2-components-video-popup
          :component="component"
          :max-height="250"
          :video-source="property.featured_video.mediaLink"
        />
      </div>
      <nc-button
        v-if="property && property.virtual_tour"
        :is-link="true"
        target="_blank"
        :link="property.virtual_tour.mediaLink"
        label="Launch Virtual Tour"
      >
        <template #label>
          <span class="tw-flex tw-items-center tw-gap-x-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.83398 17.5L8.33398 14.1667M13.334 17.5L11.6673 14.1667L9.16732 11.6667L10.0007 6.66667M10.0007 3.33333C10.0007 3.55435 10.0884 3.76631 10.2447 3.92259C10.401 4.07887 10.613 4.16667 10.834 4.16667C11.055 4.16667 11.267 4.07887 11.4232 3.92259C11.5795 3.76631 11.6673 3.55435 11.6673 3.33333C11.6673 3.11232 11.5795 2.90036 11.4232 2.74408C11.267 2.5878 11.055 2.5 10.834 2.5C10.613 2.5 10.401 2.5878 10.2447 2.74408C10.0884 2.90036 10.0007 3.11232 10.0007 3.33333Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 9.99996L6.66667 7.49996L10 6.66663L12.5 9.16663L15 9.99996"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Launch Virtual Tour
          </span>
        </template>
      </nc-button>
      <nc-button
        label="MAKE AN ENQUIRY"
        @on-click="$emit('onSendLead')"
      ></nc-button>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-y-4 tw-mt-10">
      <lazy-library-title text="My Property" tag="h3"></lazy-library-title>
      <nuxt-link
        to="/valuations"
        :external="true"
        class="share-button tw-px-7 tw-py-5 tw-flex tw-justify-center tw-items-center tw-gap-x-3 tw-rounded tw-cursor-pointer"
      >
        <span class="tw-uppercase tw-font-bold"> WHAT’S MY HOME WORTH? </span>
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { PropertyItemComponent } from '~/units/properties/types';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'PropertyDetailActions',
  components: { NcButton },

  mixins: [ComponentMixin],

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },
  },
});
</script>

<style scoped>
.share-button {
  border: 2px solid var(--camel);
}
</style>
