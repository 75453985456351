<template>
  <article v-if="blog" class="tw-py-32 lg:tw-py-[200px]">
    <lazy-library-title tag="h1" :text="blog.title"></lazy-library-title>

    <div
      v-if="blog.description"
      style="
        padding: 20px;
        color: var(--white);
        background-color: var(--primary);
      "
      class="tw-my-10"
    >
      <lazy-library-text tag="span" :text="blog.description">
      </lazy-library-text>
    </div>

    <div class="tw-mb-10 tw-mt-10 tw-w-full">
      <library-image :src="blog.largeImage"></library-image>
    </div>
    <div>
      <lazy-library-text :html="blog.content" class="blog-content"></lazy-library-text>
    </div>
  </article>
</template>

<script lang="ts">
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogMixin } from '~/units/blogs/mixins/blog.mixin';
import { useBlogsStore } from '~/units/blogs/store';

export default defineNuxtComponent({
  name: 'Theme3BlogDetail',

  mixins: [ComponentMixin, BlogMixin],

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchSingleBlog(props.component);
  },

  computed: {
    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },
  },
});
</script>

<style scoped>
:deep(.blog-content .p1) {
  font-size: 14px !important;
  font-family: var(--default-font) !important;
  line-height: unset !important;
  margin: 0 0 10px 0 !important;
}

:deep(.blog-content .p2) {
  font-size: 14px !important;
  font-family: var(--default-font) !important;
  line-height: unset !important;
  margin: 0 0 10px 0 !important;
}

:deep(.blog-content p) {
  font-size: 14px !important;
  font-family: var(--default-font) !important;
  line-height: unset !important;
  margin: 0 0 10px 0 !important;
}

:deep(.blog-content span) {
  font-size: 14px !important;
}
</style>
