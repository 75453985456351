<template>
  <div
    class="tw-absolute tw-top-32 tw-right-2 lg:tw-top-44 lg:tw-right-8 tw-items-center tw-justify-center tw-h-auto tw-z-[160]"
    id="theme3-circle-cta"
  >
    <div
      class="tw-rounded-full tw-text-white tw-py-6 tw-px-10 lg:tw-py-8 lg:tw-px-12 tw-text-center tw-border-2 tw-shadow-lg lg:tw-w-[260px] lg:tw-h-[260px] tw-flex tw-flex-col tw-items-center tw-justify-center hover:tw-border-opacity-0"
      :style="`background-color: var(--${vars.backgroundColor}); border-color: var(--${vars.borderColor})`"
    >
      <div class="tw-mb-2">
        <template v-for="(item, index) in titles" :key="`title-${index}`">
          <p
            class="tw-mb-[2px]"
            :class="{
              'font-plus-jakarta-bold tw-text-xl lg:tw-text-4xl':
                item.title_weight === 'bold',
              'font-plus-jakarta-semibold tw-text-base lg:tw-text-3xl':
                item.title_weight === 'semibold',
              'font-plus-jakarta': item.title_weight === 'regular',
            }"
          >
            {{ item.title }}
          </p>
        </template>
      </div>
      <nuxt-link
        :to="vars.buttonUrl"
        :external="true"
        :class="{
          'pxp-search-properties-btn': vars.buttonTypeText === 'primary',
          'pxp-primary-cta pxp-animate after:tw-border-inherit':
            vars.buttonTypeText === 'secondary',
        }"
        :style="`background-color: var(--${
          vars.buttonBackgroundColor
        }); color: var(--${vars.buttonTextColor}); ${
          vars.buttonTypeText === 'secondary'
            ? `border-top-color: var(--${vars.buttonTextColor});`
            : ''
        }`"
      >
        {{ vars.buttonText }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'CircleCTA',

  mixins: [ComponentMixin],

  computed: {
    titles() {
      return this.groupedVariables.titles;
    },
  },
});
</script>
<style scoped></style>
