<template>
  <div class="container mt-100">
    <h2 class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl">
      {{ vars.titleText }}
    </h2>

    <div class="row mt-4 mt-md-5">
      <div
        v-for="(office, index) in offices"
        :key="`office-${index}`"
        class="col-sm-12 col-md-6 col-lg-4"
      >
        <nuxt-link
          :to="office.link"
          :external="true"
          class="pxp-areas-1-item rounded-lg"
        >
          <NuxtImg
            :src="office.photo"
            loading="lazy"
            :alt="office.title"
            :title="office.title"
            class="pxp-areas-1-item-fig pxp-cover"
          />
          <div class="pxp-areas-1-item-details">
            <div class="pxp-areas-1-item-details-area">{{ office.title }}</div>
            <div class="pxp-areas-1-item-details-city">
              {{ office.description }}
            </div>
          </div>
          <div class="pxp-areas-1-item-cta text-uppercase">Explore</div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurLocations',

  mixins: [ComponentMixin],

  computed: {
    offices() {
      return this.groupedVariables.offices;
    },
  },
});
</script>

<style scoped>
.pxp-services-tabs-items .carousel.pxp-services-tabs-carousel {
  height: 366px;
}
</style>
