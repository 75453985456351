<template>
  <div style="position: relative">
    <library-container :key="renderKey" class="tw-py-24 tw-relative tw-z-10">
      <div class="tw-mb-8">
        <library-title tag="h3" :text="vars.titleText"></library-title>
      </div>
      <div
        v-for="(item, index) in items"
        :key="`client-testimonial-${index}`"
        class="tw-grid tw-grid-cols-12 md:tw-gap-x-16 tw-gap-y-8 md:tw-gap-y-0"
        :style="visibleIndex === index ? 'display: grid;' : 'display: none;'"
      >
        <div class="md:tw-col-span-7 tw-col-span-12 pxp-services-h-container">
          <div
            class="pxp-cover pxp-animate-in tw-rounded tw-w-full tw-h-[496px] tw-z-10 tw-relative"
            :style="`background-image: url(${item.image}); `"
          >
            <div
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              "
              @click="togglePopup(true)"
            >
              <a class="pxp-video-section-trigger tw-cursor-pointer">
                <span class="fa fa-play"></span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="md:tw-col-span-5 tw-col-span-12 tw-flex tw-flex-col tw-justify-end tw-gap-y-4"
        >
          <div class="tw-flex tw-gap-x-3">
            <span
              v-for="i in item.star_count"
              :key="`star-${i}`"
              class="fa fa-star tw-text-2xl"
              :style="`color: var(--camel)`"
            ></span>
          </div>
          <library-title
            tag="h4"
            :text="item.title"
            style="font-weight: 900"
          ></library-title>
          <library-text :text="item.description"> </library-text>
          <library-text
            :text="item.author"
            style="color: #808080; font-weight: bold"
          ></library-text>
          <div class="tw-mt-8 tw-pl-6" @click="goNextOne()">
            <div
              class="pxp-areas-carousel-right-arrow pxp-animate"
              style="opacity: 1 !important; position: static !important"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32.414"
                height="20.828"
                viewBox="0 0 32.414 20.828"
              >
                <g
                  id="Symbol_1_1"
                  data-name="Symbol 1 – 1"
                  transform="translate(-1847.5 -1589.086)"
                >
                  <line
                    id="Line_2"
                    data-name="Line 2"
                    x2="30"
                    transform="translate(1848.5 1599.5)"
                    fill="none"
                    stroke="#333"
                    stroke-linecap="round"
                    stroke-width="2"
                  ></line>
                  <line
                    id="Line_3"
                    data-name="Line 3"
                    x2="9"
                    y2="9"
                    transform="translate(1869.5 1590.5)"
                    fill="none"
                    stroke="#333"
                    stroke-linecap="round"
                    stroke-width="2"
                  ></line>
                  <line
                    id="Line_4"
                    data-name="Line 4"
                    y1="9"
                    x2="9"
                    transform="translate(1869.5 1599.5)"
                    fill="none"
                    stroke="#333"
                    stroke-linecap="round"
                    stroke-width="2"
                  ></line>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </library-container>
    <div
      class="tw-absolute tw-w-full tw-h-[260px] tw-mt-10 lg:tw-mt-0 tw-top-0 md:tw-w-[330px] lg:tw-w-[530px] md:tw-h-[406px] md:tw-top-[unset] md:tw-bottom-0 md:tw-left-0 tw-z-0"
      style="background-color: var(--purple)"
    ></div>
  </div>

  <library-popups-video-popup
    v-model:is-visible="isPopupVisible"
    :video-url="visibleTestimonial.video"
  />
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { produceKey } from '~/server/core/helpers';
import { jQuery } from 'public/theme3/js/custom';

export default defineNuxtComponent({
  name: 'Theme3ClientTestimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      isPopupVisible: false,
      visibleIndex: 0,

      renderKey: produceKey(),
    };
  },

  computed: {
    visibleTestimonial() {
      return this.items[this.visibleIndex];
    },

    items() {
      return this.groupedVariables.items;
    },
  },

  methods: {
    togglePopup(status = false) {
      this.isPopupVisible = status;
    },

    goNextOne() {
      if (this.items.length !== this.visibleIndex + 1) {
        this.visibleIndex += 1;
      } else {
        this.visibleIndex = 0;
      }
      this.renderKey = produceKey();
      this.$nextTick(() => {
        jQuery.runAnimation();
      });
    },
  },
});
</script>
