<template>
  <div
    class="pxp-services tw-flex tw-justify-center tw-items-center tw-h-[418px] pxp-cover mt-100 pt-100 mb-200"
    style="background-position: 50% 60%"
    :style="`background-image: url(${vars.backgroundImage});`"
  >
    <div
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-shadow-[0px_15px_30px_rgba(0,0,0,0.1)] rounded-lg tw-mt-52"
    >
      <h2
        class="font-plus-jakarta-semibold text-center tw-mb-8 tw-text-3xl lg:tw-text-4xl tw-text-white"
      >
        Why Choose Us
      </h2>
      <div class="tw-w-full tw-max-w-[818px]">
        <div
          class="pxp-areas-carousel-item tw-rounded-md tw-py-7 tw-px-6 !tw-flex tw-flex-col tw-gap-10 tw-h-[428px] tw-items-center"
          :style="`background-color: var(--${vars.cardBackgroundColor})`"
        >
          <div>
            <span
              v-for="i in vars.ratingNumber"
              :key="`rating-${i}`"
              class="fa fa-star tw-mr-1"
              :style="`color: var(--camel)`"
            ></span>
          </div>
          <div class="tw-text-center">
            <h3 class="tw-mb-2 font-arial-bold tw-text-lg">
              {{ vars.testimonialTitleText }}
            </h3>
            <p
              class="font-arial tw-truncate tw-line-clamp-[7]"
              style="white-space: unset"
            >
              {{ vars.testimonialDescriptionText }}
            </p>
            <h3 class="tw-mt-7 font-arial-bold">
              {{ vars.testimonialAuthorText }}
            </h3>
          </div>
          <h3 class="tw-mt-auto">
            <nuxt-link
              :to="vars.buttonHrefUrl"
              :external="true"
              :class="{
                'pxp-search-properties-btn': vars.buttonTypeText === 'primary',
                'pxp-primary-cta pxp-animate after:tw-border-inherit':
                  vars.buttonTypeText === 'secondary',
              }"
              :style="`background-color: var(--${
                vars.buttonBackgroundColor
              }); color: var(--${vars.buttonTextColor}); ${
                vars.buttonTypeText === 'secondary'
                  ? `border-top-color: var(--${vars.buttonTextColor});`
                  : ''
              }`"
            >
              {{ vars.buttonText }}
            </nuxt-link>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'SingleTestimonial',

  mixins: [ComponentMixin],
});
</script>
