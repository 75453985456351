<template>
  <div
    class="pxp-services pxp-cover mt-100 pt-100 mb-200"
    style="background-position: 50% 60%"
    :style="`background-image: url(${vars.backgroundImage});`"
  >
    <div class="container tw-max-w-4xl text-center">
      <library-title
        tag="h2"
        :text="vars.titleText"
        class="tw-text-white tw-text-center"
        style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)"
      />
      <library-text
        v-if="vars.descriptionText"
        :text="vars.descriptionText"
        class="tw-text-white tw-mt-6"
      />
    </div>

    <div class="container">
      <div class="pxp-services-container tw-h-[unset] rounded-lg mt-4 mt-md-5">
        <div
          v-for="(card, index) in cards"
          :key="`card-${index}`"
          class="pxp-services-item tw-flex tw-text-center tw-justify-center"
          :class="{ 'tw-px-12': vars.textNowrapNumber === 1 }"
          style="background: var(--isabelline)"
        >
          <div
            class="tw-py-10 tw-px-8 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4"
          >
            <NuxtImg v-if="card.icons" :src="card.icons" class="tw-h-16" />
            <h2
              v-if="card.title"
              class="font-arial-bold tw-text-lg"
              :class="{
                'tw-text-nowrap': vars.textNowrapNumber === 1,
              }"
            >
              {{ card.title }}
            </h2>
            <p
              v-if="card.description"
              class="font-arial"
              v-html="card.description"
            ></p>
            <nuxt-link
              v-if="card.button_label"
              to="#"
              :external="true"
              :class="{
                'pxp-search-properties-btn': card.button_type === 'primary',
                'pxp-primary-cta pxp-animate after:tw-border-inherit':
                  card.button_type === 'secondary',
              }"
              :style="`background-color: var(--${
                card.button_background
              }); color: var(--${card.button_text}); ${
                card.button_type === 'secondary'
                  ? `border-top-color: var(--${card.button_text});`
                  : ''
              }`"
            >
              <!-- TODO COCKPIT for only Grouped Variables: background-> background-color, text -> text-color -->
              {{ card.button_label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhyChooseUs',

  mixins: [ComponentMixin],

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>

<style scoped></style>
