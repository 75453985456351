<template>
  <section class="pb-100" style="background: var(--isabelline)">
    <div class="container">
      <div class="tw-text-center tw-pb-16">
        <h2 class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl">
          {{ vars.titleText }}
        </h2>
      </div>
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-20 tw-text-center"
      >
        <div
          v-for="(value, index) in values"
          :key="`value-${index}`"
          class="tw-flex tw-flex-col tw-items-center tw-gap-3"
        >
          <NuxtImg
            :src="value.photo"
            loading="lazy"
            :alt="value.title"
            quality="60"
            class="tw-h-32"
          />
          <h3 class="font-arial-bold tw-text-2xl tw-mb-2">{{ value.title }}</h3>
          <p>{{ value.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoBackground',

  mixins: [ComponentMixin],

  computed: {
    values() {
      return this.groupedVariables.values;
    },
  },
});
</script>
